<template>
  <div class="globalTemplate" :style="{fontFamily: this.cv.typeOfLetter, textAlign:this.cv.align}">
    <div class="contact-secction-ambience" >
        <div>
            <h1>
                {{ cv.contact.firstName }} {{ cv.contact.lastName }}     
            </h1>
            <section class="centerUppercase" v-if="cv.listExperience.length>0">
                <h2 :style="{fontFamily: this.cv.typeOfLetter}">- {{cv.listExperience[0].job}} -</h2>
            </section>
            <p id="contactInfo">
                <section>
                    <i class="fas fa-map-marker-alt fa-2x"></i>
                    <p>{{ cv.contact.address}}, {{ cv.contact.city }}, {{ cv.contact.postal }}</p>
                </section>
                <section>
                    <i class="fas fa-phone-alt fa-2x"></i>
                    <p>{{ cv.contact.phone }}</p>
                </section>
                <section>
                    <i class="fas fa-envelope fa-2x"></i>
                    <p>{{ cv.contact.email }}</p>
                </section>
            </p>
        </div>
    </div>
    <div class="header-div-simple">
        <div class="west-secction">
            <div>
                <img :src="cv.img" alt="candidate picture" />
                <div class="aboutMe-modern">
                    <h2>Sobre mí</h2>
                    <p class="info-simple verifyPagesCv">
                        {{cv.bio}}
                    </p>
                </div>
                <div> 
                  <h2>Formación</h2>
                    <div
                        v-for="(item, index) in cv.listEducation"
                        :key="index"
                        class="experienceClass-simple verifyPagesCv"
                        >
                        <div >
                            <h3>{{ item.degree  }}</h3>
                            <p class="location">{{ item.nameSchool }} | {{ item.city }} |  {{  item.date ? formatDate (item.date) : "..." }} </p>
                            <p>
                                {{ item.bio }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class= "main-secction">
            <h2 class="noTopMargin">Experiencia profesional</h2>
            <div
            v-for="(item, index) in cv.listExperience"
            :key="'A'+ index"
            class="experienceClass-simple verifyPagesCv"
            >
                <div >
                    <h3>{{ item.job  }}</h3>
                    <p class="location">{{ item.employer }} | {{ item.city }} | {{ item.dateStart ? formatDate (item.dateStart) : "..." }} ~ {{item.checkbox ? "Presente" : formatDate (item.dateEnd)}} </p>
                    <p>
                        {{ item.bio }}
                    </p>
                </div>
            </div>
            <h2>Aptitudes</h2>
            <div class="skills" v-for="(item, index) in cv.listSkill" 
            :key="'B'+ index">
                {{ item.name }}
                <div class="progress-simple verifyPagesCv">
                    <div :class="getStyle(item.level)"></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`
    },
  },
  mounted(){
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  }
};
</script>

<style scoped>
i{
    padding: 10px;
    color: #CF9604;
}
img {
    width: 100%;
    display: block;
    margin: auto;
    object-fit: cover;
}
h1 {
    font-family: Playfair Display;
    text-align: center;
    font-size: 3em;
}
h2{
    font-family: Playfair Display;
    font-size: 1.4em;
    margin-top: 1.4em;
    color:#CF9604;
    clear: both;
    text-transform: uppercase;
}
h3 {
    color: #000000;
}

.contact-secction-ambience {
  padding-top: 2em;
  width: 100%;
  background-color: #283041;
  color : white;
}
.contact-secction-ambience .centerUppercase {
    text-align:center; 
    text-transform: uppercase;
}
.contact-secction-ambience .centerUppercase h2 {
    color : white;
    margin-top: 0;
    font-weight: normal;
}
#contactInfo {
    display:flex; 
    padding: 2.5em 0 1em 0;
    clear:both;
}
#contactInfo section {
    width: 33%;
    text-align: center;
}
#contactInfo section:nth-child(1) p { padding-left: 30px;}
.header-div-simple p{
    font-weight: 300;
    color: #161616;
}
.header-div-simple .location {
    color: #828282;
    font-weight: bold;
}
.main-secction{
    /* min-height: 77vh; */
    width: 64%;
    padding: 10px 2.8em;
    /* height: 100%; */
}
.noTopMargin {margin-top: 0px;}
.west-secction{
    height: 100%;
    width: 36%;
    padding: 10px 50px;
    float: left;
    border-right: 1px solid #C4C4C4;
}
.west-secction .experienceClass-simple.verifyPagesCv p {font-size: 0.9em;}
.west-secction .experienceClass-simple.verifyPagesCv h3 {line-height: 1.2em;}
</style>
