<template>
  <div class="globalTemplate" :style="{fontFamily: this.cv.typeOfLetter, textAlign:this.cv.align}">
    <div class="topSublime">
        <section>
            <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
            <h2 v-if="cv.listExperience.length>0">{{cv.listExperience[0].job}}</h2>
        </section>
        <section>
            <h2>Sobre mí</h2>
            <p>{{cv.bio}}</p>
        </section>
    </div>
    <div class="mainSublime">
        <section class="westSublime">
            <div> 
                <h2>Formación</h2>
                <div
                    v-for="(item, index) in cv.listEducation"
                    :key="index"
                    class="verifyPagesCv"
                    >
                    <h3>{{ item.degree  }}</h3>
                    <h4>{{ item.nameSchool }} | {{ item.city }} |  {{  item.date ? formatDate (item.date) : "..." }} </h4>
                    <p>{{ item.bio }}</p>
                </div>
                <h2>Aptitudes</h2>
                <div  v-for="(item, index) in cv.listSkill" 
                :key="'B'+ index">
                    <p class="skillsSublime">{{ item.name }}</p>
                    <div class="progress-concept verifyPagesCv" >
                        <div v-bind:class="getStyle(item.level)"></div>
                    </div>
                </div>
            </div>
        </section>
        <section class= "eastSublime">
            <div>
                <div>
                    <img v-bind:src="cv.img" alt="candidate picture"/>
                </div>
                <div>
                    <p>
                        <i class="fas fa-map-marker-alt"></i>
                        {{ cv.contact.address}}, {{ cv.contact.city }}, {{ cv.contact.postal }}
                    </p>
                    <p>
                        <i class="fas fa-phone-alt"></i>
                        {{ cv.contact.phone }}
                    </p>
                    <p>
                        <i class="fas fa-envelope"></i>
                        {{ cv.contact.email }}
                    </p>
                </div>
            </div>
            <h2>Experiencia profesional</h2>
            <div
            v-for="(item, index) in cv.listExperience"
            :key="'A'+ index"
            class="verifyPagesCv"
            >
                <h3>{{ item.job  }}</h3>
                <h4>{{ item.employer }} | {{ item.city }} | {{ item.dateStart ? formatDate (item.dateStart) : "..." }} ~ {{item.checkbox ? "Presente" : formatDate (item.dateEnd)}} </h4>
                <p>{{ item.bio }}</p>
            </div>
        </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`;
    },
  },
  mounted(){
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  }
};
</script>

<style scoped>
i{
  color: #CF9603;
}
h1 {
    font-family: Helvetica;
    font-size: 2em;
    color: #161616;
}
h2{
    font-family: Helvetica;
    font-weight: 300;
    font-size: 1.5em;
    color:#CF9603;
    text-transform: uppercase;
}
h4 {
    color: #828282;
}
img{
  width: 100%;
  object-fit: fill;
}
p {
    font-weight: 300;
    color: #161616;
}
.globalTemplate {
    padding: 2em 3em;
}
.topSublime section:nth-child(1) {
    width: 50%;
    float: left;
}
.topSublime section:nth-child(2) {
    width: 50%;
    float: right;
    padding-left: 2em;
    border-left: 0.5px solid #E5E5E5;
}
.topSublime section:nth-child(1) h2 {
    color: #161616;
}
.mainSublime {
  display: flex;
  width: 100%;
}
.westSublime {
    width: 36%;
    float: left;
    padding-right: 2em;
}
.eastSublime {
    width: 64%;
    float: right;
    padding-left: 2em;
    border-left: 0.5px solid #E5E5E5;
}
p.skillsSublime {
  margin: 0.5em 0 0 0;
}
.eastSublime > div:nth-child(1) div:nth-child(1) {
    width: 35%;
    float: left;
}
.eastSublime > div:nth-child(1) div:nth-child(2) {
    width: 65%;
    float: right;
    padding: 0.8em 0 0 2em;
}
.eastSublime > div:nth-child(1) div:nth-child(2) p {
    margin-bottom: 0.2em;
}
.eastSublime h2 {
    clear: both;
    padding-top: 1em;
}
</style>
