<template>
  <div class="globalTemplate" :style="{fontFamily: this.cv.typeOfLetter, textAlign:this.cv.align}">
    <div class="topCascade">
      <section>
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length>0">{{cv.listExperience[0].job}}</h2>
      </section>
      <section>
        <p>
          <i class="fas fa-map-marker-alt"></i>
          {{ cv.contact.address}}, {{ cv.contact.city }}, {{ cv.contact.postal }}
        </p>
        <p>
          <i  class="fas fa-phone-alt"></i>
          {{ cv.contact.phone }}
        </p>
        <p>
          <i class="fas fa-envelope"></i>
          {{ cv.contact.email }}
        </p>
      </section>
    </div>
    <div class="mainPrimary">
      <section>
        <h2 >Sobre mí</h2>
        <p class="verifyPagesCv">{{ cv.bio }}</p>
        <h2>Aptitudes</h2>
        <div class="verifyPagesCv" v-for="(item, index) in cv.listSkill"
        :key="index">
          <p class="skillsPrimary">{{ item.name }}</p>
          <div class="progress">
            <div v-bind:class="getStyle(item.level)"></div>
          </div>
        </div>
      </section>
      <section class="eastPrimary">
        <h2>Experiencia profesional</h2>
        <div
          v-for="(item, index) in cv.listExperience"
          :key="'A'+ index"
        >
          <div class="locationWest verifyPagesCv">
            <h3>{{ item.employer }}</h3>
            <h4>{{ item.city }}</h4>
            <h4>
              {{ item.dateStart ? formatDate (item.dateStart) : "..." }} ~ {{item.checkbox ? "Presente" : formatDate (item.dateEnd)}}
            </h4>
          </div>

          <div class="descriptionEeast">
            <h3>{{ item.job }}</h3>
            <p>{{ item.bio }}</p>
          </div>
        </div>
        <h2>Formación</h2>
        <div
          v-for="(item, index) in cv.listEducation"
          :key="'B'+ index"
        >
          <div class="locationWest verifyPagesCv" >
            <h3>{{ item.nameSchool }}</h3>
            <h4>{{ item.city }}</h4>
            <h4>{{  item.date ? formatDate (item.date) : "..." }}</h4>
          </div>
          <div class="descriptionEeast">
            <h3>{{ item.degree }}</h3>
            <p>{{ item.bio }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`
    },
  },
  mounted(){
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  }
};
</script>
<style scoped>
h1 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 2em;
}
.topCascade h2 {
  color: #4F4F4F;
  font-weight: 500;
  font-size: 1.5em;
}
.mainPrimary h2 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.5em;
  border-bottom: 0.5px solid #E0E0E0;
  margin: 0.8em 0;
}
h3 {
  font-weight: 500;
}
h4 {
  font-weight: 500;
  color: #828282;
  font-size: 0.8em;

}
.topCascade {
  padding: 0 3em;
  /* display:flex;
  align-items: stretch; */
  padding-top: 2em;
  width: 100%;
}
.topCascade section:nth-child(1){
  width: 60%;
  float: left;
}
.topCascade section:nth-child(2){
  width: 40%;
  float: right; 
}
.topCascade section:nth-child(2) p {
  width: 80%;
  padding: .8em 0;
  margin: 0;
}
.topCascade section:nth-child(2) p:nth-child(1), .topCascade section:nth-child(2) p:nth-child(2) {
  border-bottom: 0.5px solid #E0E0E0;
}
.mainPrimary {
  display: flex;
  width: 100%;
  height: 100%;
  clear: both;
}
.mainPrimary section:nth-child(1){
  color: white;
  background:black;
  width: 36%;
}
.mainPrimary section{
  padding: 1.5em 3em;
}
p.skillsPrimary {
  margin-bottom: 0;
}
.locationWest {
  padding-right: 1em;
}
.eastPrimary {
  width: 70%;
  float: right;
}
.eastPrimary > div {
  clear: both;
}
.globalTemplate {
    overflow: hidden;
  }
</style>
