<template>
  <div class="globalTemplate" :style="{fontFamily: this.cv.typeOfLetter, textAlign:this.cv.align}">
    <div class="westModern">
      <section>
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length>0">- {{cv.listExperience[0].job}} -</h2>
        <img v-bind:src="cv.img" alt="candidate picture"/>
      </section>
      <section>
        <h2>Información de contacto</h2>
        <div>
          <p>
            <i class="fas fa-map-marker-alt"></i>
            {{ cv.contact.address}}, {{ cv.contact.city }}, {{ cv.contact.postal }}
          </p>
          <p>
            <i class="fas fa-phone-alt"></i>
            {{ cv.contact.phone }}
          </p>
          <p>
            <i class="fas fa-envelope"></i>
            {{ cv.contact.email }}
          </p>
        </div>
      </section>
      <section>
        <h2>Experiencia profesional</h2>
        <div
        v-for="(item, index) in cv.listExperience"
        :key="'A'+ index"
        class="verifyPagesCv"
        >
          <h3>{{ item.job  }}</h3>
          <h4>{{ item.employer }} | {{ item.city }} | {{ item.dateStart ? formatDate (item.dateStart) : "..." }} ~ {{item.checkbox ? "Presente" : formatDate (item.dateEnd)}} </h4>
          <p>{{ item.bio }}</p>
        </div>
      </section>
    </div>
    <div class="eastModern">
      <section>
          <h2>Sobre mí</h2>
          <p class="verifyPagesCv">{{cv.bio}}</p>
      </section>
      <section> 
        <h2>Formación</h2>
        <div
          v-for="(item, index) in cv.listEducation"
          :key="index"
          class=" verifyPagesCv"
        >
            <h3>{{ item.degree  }}</h3>
            <h4>{{ item.nameSchool }} | {{ item.city }} |  {{  item.date ? formatDate (item.date) : "..." }} </h4>
            <p>{{ item.bio }}</p>
        </div>
        <h2>Aptitudes</h2>
        <div class="verifyPagesCv" v-for="(item, index) in cv.listSkill" 
        :key="'B'+ index">
            <p class="skillsModern">{{ item.name }}</p>
            <div class="progress-simple">
                <div v-bind:class="getStyle(item.level)"></div>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`
    },
  },
  mounted(){
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  }
};
</script>

<style scoped>
* {
    color: #161616;
}
h1 {
  font-family: Bebas Neue;
  font-size: 2.5em;
  font-weight: normal;
}
.westModern section:nth-child(2) h2, .westModern section:nth-child(3) h2, .eastModern h2{
  font-family: Bebas Neue;
  font-weight: normal;
  font-size: 2em;
  color:#D6B9A8;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: 1em;
}
.westModern section:nth-child(1) h2{
  font-weight: normal;
  font-size: 1.5em;
  text-transform: uppercase;
}
h3 {
  font-size:1em;
}
h4{
  color: #828282;
}
i{
  color: #1F425B;
  padding-right: 1em;
}
img{
  width: 23%;
  top: 0;
  position: absolute;
  margin-left: 2em;
}
.westModern{
  width: 58%;
  float: left;
  padding: 3em 0 3em 3em;
}
.westModern > section:nth-child(1){
  text-align: right;
  width: 60%;
  text-transform: uppercase;
}
.westModern section:nth-child(2) p {
  margin-bottom: 0;
  color: #000000;
}
.westModern section:nth-child(2) {
  margin-top: 6em;
}
.eastModern{
  width: 42%;
  float: right;
}
.eastModern section {
  padding: 0 3em 0 4.5em;
}
.eastModern > section:nth-child(1){
  width: 100%;
  background-color: #F9F0EB;
  display: inline-block;
  margin-top: 3em;
}
p.skillsModern {
  margin-bottom: 0;
}
p {
  font-weight: 300;
  color: #161616;;
}
</style>
