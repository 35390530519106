<template>
  <div class="globalTemplate" :style="{fontFamily: this.cv.typeOfLetter, textAlign:this.cv.align}">
    <div class="westCombined" >
      <section>
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length>0">{{cv.listExperience[0].job}}</h2>
        <div> 
          <p>
            <i class="fas fa-map-marker-alt"></i>
            {{ cv.contact.address}}, {{ cv.contact.city }}, {{ cv.contact.postal }}
          </p>
          <p>
            <i class="fas fa-phone-alt"></i>
            {{ cv.contact.phone }}
          </p>
          <p>
            <i class="fas fa-envelope"></i>
            {{ cv.contact.email }}
          </p>
        </div>
      </section>
      <div>
        <h2>Experiencia profesional</h2>
        <div
        v-for="(item, index) in cv.listExperience"
        :key="'A'+ index"
        class="verifyPagesCv"
        >
          <div>
            <h3>{{ item.job  }}</h3>
            <h4>{{ item.employer }} | {{ item.city }} | {{ item.dateStart ? formatDate (item.dateStart) : "..." }} ~ {{item.checkbox ? "Presente" : formatDate (item.dateEnd)}} </h4>
            <p>{{ item.bio }}</p>  
          </div>
        </div>
        <h2>Aptitudes</h2>
        <div class="verifyPagesCv" v-for="(item, index) in cv.listSkill" :key="'B'+ index">
          {{ item.name }}
          <div class="progress-simple">
              <div v-bind:class="getStyle(item.level)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="eastCombined">
      <img :src="cv.img" alt="candidate picture"/>
      <div>
        <h2>Sobre mí</h2>
        <p class="verifyPagesCv">{{cv.bio}}</p>
        <h2>Formación</h2>
        <div
          v-for="(item, index) in cv.listEducation"
          :key="index"
          class="verifyPagesCv"
        >
          <h3>{{ item.degree  }}</h3>
          <h4>{{ item.nameSchool }} | {{ item.city }} |  {{  item.date ? formatDate (item.date) : "..." }} </h4>
          <p>{{ item.bio }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`
    },
  },
  mounted(){
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  }
};
</script>

<style scoped>
img {
    width: 100%;
    height: 15em;
    display: block;
    margin: auto;
    object-fit: cover;
}
h1 {
  font-family: Bebas Neue;
  font-size: 2.8em;
  font-weight: normal;
}
h4 {
  color: #828282;
}
i{
  color: #1F425B;
  padding-right: 1em;
}
p{
  font-weight: 300;
}
.eastCombined {
  width: 35%;
  background-color: #1F425B;
  color: white;
  float: right;
}
.eastCombined > div{
  padding: 2em;
}
.eastCombined h2 {
  font-family: Bebas Neue;
  font-size: 1.8em;
  font-weight: normal;
  color:#FFFFFF;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}
.globalTemplate{
  display: flex;
}
.progress-simple {
  margin-bottom: .8em;
}
.westCombined{
  width: 65%;
  float: left;
  padding: 1.8em 2.5em;
}
.westCombined div > h2{
  font-family: Bebas Neue;
  font-size: 1.8em;
  font-weight: normal;
  color:#1F425B;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: 1.2em;
}
.westCombined > section > h2 {
  color: #000000;
  font-weight: normal;
  margin: -0.3em 0 1em 0;
}
.westCombined > section p {
  margin: 0;
}

</style>
